html,
body,
#root {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  width: 100%;
  height: 100%;
  font-family: "gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

body {
  color: rgb(196, 201, 206);
  text-rendering: optimizeLegibility;
  font-weight: 400;
  line-height: 1.5;
  background-color: rgb(49, 51, 56);
}

.Container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  min-height: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Section {
  width: 890px;
  min-width: 890px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.Header span {
  font-weight: 600;
}

.Card {
  width: 100%;
  box-sizing: border-box;
  background-color: hsl(220, calc(var(--saturation-factor, 1) * 6.5%), 18%);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  transition: border 0.125s;
  border-color: hsl(0, calc(var(--saturation-factor, 1) * 0%), 0.8%);
  padding: 20px;
}

.Heading {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 8px;
}

h1 {
  font-size: 2rem;
  color: #fff;
}

.Description {
  margin-bottom: 20px;
}
.GameBoxContainer {
  grid-auto-rows: 1fr;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.GameBox {
  background-color: hsl(220, calc(var(--saturation-factor, 1) * 8.1%), 7.3%);
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  cursor: pointer;
  user-select: none;
}
.GameBox * {
  cursor: pointer;
  user-select: none;
}
.GameBox .GameBoxInfo {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
}
.GameBoxInfo img {
  width: 65px;
}
.GameBoxInfo div {
  flex-grow: 1;
}
.GameBox input {
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: rgba(0, 0, 0, 0);
}
.ActionContainer {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.Button {
  height: auto;
  background-color: rgb(88, 101, 242);
  color: #fff;
  font-size: 15px;
  border-radius: 3px;
  padding: 10px 16px;
  outline: 0;
  border: 0;
}
